import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts"
import Image from "gatsby-plugin-sanity-image"
import SEO from "../components/SEO"
import styled from "styled-components"
const BottomSpacer = styled.div`
  margin-bottom: 400px;
  @media (max-width: 550px) {
    margin-bottom: 500px;
  }
  @media (max-width: 400px) {
    margin-bottom: 700px;
  }
`
const Wrapper = styled.div`
  text-align: center;
`
const FourDiv = styled.div`
  margin: auto;
  padding: 10px;
`
const FourSemantics = styled(Image)`
  width: 300px;
  padding: 10px;
`
const VitvanDiv = styled.div`
  width: 300px;
  padding: 10px;
  margin: auto;
`
const Vitvan = styled(Image)`
  padding: 10px;
  transform: scaleX(-1);
`
const ParagraphGrid = styled.div`
  display: grid;

  grid-template-columns: 1fr 0.3fr;
  @media (max-width: 975px) {
    grid-template-columns: 1fr;
  }
`
const SecondParagraphGrid = styled.div`
  display: grid;

  grid-template-columns: 0.5fr 1fr;
  @media (max-width: 975px) {
    grid-template-columns: 1fr;
  }
`

const Paragraph = styled.div`
  margin: auto;
`

const Meditation = ({ data }) => {
  return (
    <>
      <SEO
        title="Meditation"
        description="to engage in mental exercise (such as concentration on one's breathing or repetition of a mantra) for the purpose of reaching a heightened level of spiritual awareness"
      />

      <Layout>
        <Wrapper>
          <h2 style={{ fontWeight: "bold" }}>
            Meditation as taught in The School of The Natural Order
          </h2>
          <ParagraphGrid>
            <Paragraph>
              <p>
                In describing the method of meditation as practiced in the
                School of the Natural Order, I direct your attention to energy,
                vital force, elan, or whatever other label you choose to use. It
                is perfectly obvious that you cannot function without energy. If
                the energy with which you now function should suddenly
                disappear, you couldn’t sit upright in your chair, couldn’t
                talk, couldn’t think, couldn’t lift a feather. As it is in
                everything that you can think about or mention, so it is on all
                of the higher levels. You cannot function on any level, even
                that most objective self conscious state, without energy. Our
                electricians, electrical engineers, etc., use the word energy
                when they refer to electricity, so you could substitute the word
                electricity for energy. As it is in everything that you see,
                experience, and know, so it is on succeedingly higher levels. It
                requires energy to function, to be conscious.
              </p>
              <p>
                When we work our understanding into the so-called higher levels,
                we find that energy has a wide range of gradations in fineness.
                As one develops functional ability on the inner or higher levels
                the wave lengths become so short and the frequencies so high
                that there is almost no way to differentiate between
                frequencies. So energy is not the same on every level. You must
                allow for degrees of fineness. If we were all electronic
                engineers, we would probably have mathematical formulas to
                describe this; but you get the idea. Focus the thought upon
                energy. It is prerequisite to functioning upon any level.
              </p>
            </Paragraph>
            {data.allSanityImages.nodes.map((node, i) => {
              if (node.title === "Vitvan at Table 2") {
                return (
                  <VitvanDiv key={node.title}>
                    <Vitvan
                      {...node.image}
                      alt={node.title}
                    />
                    <h5>
                      <strong>Vitvan</strong>
                    </h5>
                  </VitvanDiv>
                )
              }
            })}
          </ParagraphGrid>

          <p>
            The next step after that thought is established is breath. One must
            learn breath work before he attempts meditation. We use what we call
            the full breath. Sit down, keeping your spine straight and your head
            erect, hands in your lap, palms up. Relax. Find a relaxed position.
            Inhale air deep into your lungs, and then gradually exhale. Inhale
            slowly and steadily, exhale slowly and steadily, and keep practicing
            that full breath until with the breath in your lungs you can feel
            your collarbones lifting. It sounds easy, and many people think that
            they breathe deeply, but a vast majority are shallow breathers.
            Mountain climbers and athletes breathe deeply; they must do it. But
            those who are not mountain climbers and athletes are generally
            shallow breathers. It takes quite a little effort to get over the
            habit of shallow breathing and to take a full breath. When you think
            that you have your lungs full of air, breathe in some more. Quite a
            little practice is required to learn how to take a full breath.
          </p>
          <h4 style={{ fontWeight: "bold" }}>
            The Four Somatic Divisions of Man
          </h4>
          <p>
            Now we have two factors: the thought that we’re dealing with energy
            and the practice of taking a full breath. This is only preliminary.
            We’re getting ready to meditate. Then, turn the thought upon the
            four somatic divisions of yourself, of ‘man’. The lowest of the four
            is symbolized by earth; the next by water; the next by air; and the
            highest, in the head centers, symbolized by fire. These are a part
            of zodiacal symbolism, which is only a language that describes the
            functions. It does not mean that when Venus is in Libra that
            anything is going to happen to you. It simply means that when you
            have the force in the heart center the experience that you will have
            is describable as Venus in Libra. And when your forces are down in
            the solar center and Mars is in Scorpio, it means that you are wont
            to quarrel and fight, even when everything is harmonious and
            peaceful. You’ll stir up something so you can have a good quarrel.
          </p>
          <SecondParagraphGrid>
          {data.allSanityImages.nodes.map((node, i) => {
              if (node.title === "The Four Somatic Divisions of 'Man'") {
                return (
                  <FourDiv key={node.title}>
              <FourSemantics
                {...node.image}
                alt={node.title}
              />
              <h4>
                <strong>The Four Somatic Divisions of 'Man</strong>
              </h4>
            </FourDiv>
                )
              }
            })}
     
            <Paragraph>
              <p>
                Those are two of the symbols that characterize the functions of
                a given ‘man’. One breaks through from objective
                self-consciousness to the awakening of five centers in the
                psyche, the psychic nature, the personality pattern. Prior to
                the awakening of the five psychic centers, one functions by
                neural sensation, sensuous receptivity—seeing, hearing,
                smelling, tasting, touching. Before the centers in the psyche
                awaken, he’s limited to neural, sensuous functioning. But as
                soon as the psychic centers awaken, he is responsive to psychic
                influences, wave-frequencies, vibrations. He registers
                vibrations of thoughts, feelings and desires in his environment,
                and particularly with those with whom he is en rapport. When one
                forms a synchronization of frequencies with another he takes on
                and assumes the conditions of the other, to a greater or lesser
                degree. The greater or lesser degree depends upon which is the
                most positive and which is the negative. The negative one will
                take on more, the positive one less.
              </p>

              <p>
                When one breaks through objective identification, limited to the
                neural sense functioning, he begins to be conscious of the wave
                frequencies, vibrations, of places, persons, things, etc., and
                takes them on to a greater or lesser degree. When he practices
                healing work, the first thing we do with the one in consultation
                is find out his rapports, his environment, his background, his
                associates. We locate all those influences because when we do,
                ninety times out of every hundred cases, we’ve got the cause of
                his suffering and what’s eating on him, and even on his
                configurational representation. When we can locate those psychic
                influences and wave-frequencies of a finer quality than the
                neural functioning, then in ninety cases out of every hundred,
                we’ve located the cause of physiological disease and
                maladjustments, as well as psychological mixups, neuroses and so
                on. Then we can work on eliminating the cause and the effects
                will clear up.
              </p>
            </Paragraph>
          </SecondParagraphGrid>
          <p>
            This breakthrough into the registry of the psychic world is
            extremely important. I would love to address meetings of
            psychiatrists and psychoanalysts because they’re all at sixes and
            sevens among themselves. To me they’re the blind trying to lead the
            blind. I’d like to lay it al I down to them and prove every
            statement made regarding the etiology of psychological
            maladjustments.
          </p>
          <p>
            Let us return to the point. This lesson is on meditation, but I had
            to bring in the vast ramifications relative to understanding the
            psyche and its centers and the way it functions. After the
            breakthrough one becomes more and more conscious of the
            wave-frequencies on the psychic side, which for a long time are
            unconscious—what the psychiatrists and psychologists would call: in
            the unconscious. “In the unconscious” simply means this: during the
            long, long time that we have been in objective self-consciousness
            we’ve been objectified, and that habit of objectifying everything—
            ‘thingifying’, we call it hangs over long after the psychic centers
            are awakened and stirring. We still persist in objectifying, and
            that constitutes a heavy wall, a heavy veil, between the mental,
            cortical, objective thinking and the psychic functions. Gradually—
            meaning four, five, six, seven palingenetic cycles—that wall gets
            thinner and thinner, down to a veil, and by-and-by the veil
            disappears and we perceive just one world.
          </p>
          <p>
            We function in this so-called objective sense just as we would if we
            didn’t have a body or a brain; just precisely the way we do when we
            check out in what the blessed Aristotle fans call death. We function
            right now, all the time, just like we would if we had no body and no
            brains. And what a ruse the brain is, because when we have that
            brain working, the more we think the less we know. Functioning in
            the frequency world by frequency registration and responsiveness to
            the quality, the guna of the frequency registration, is far beyond
            the best brains. I’ve had occasion through the years to point to
            this one or that one who is highly successful in the objective sense
            and show that these ones never functioned by brains at all. They
            functioned by their higher psychic centers and psychic forces.
          </p>
          <p>
            I remember reading the autobiographical sketch of John D.
            Rockefeller, and I bring that in because these aristotelians often
            want to be millionaires. He was the head of the board of many
            far-flung oil companies. He controlled the majority of the stock in
            all those companies. Many are the times, he said in his
            autobiographical accounts, that he stood before his boards and
            opposed every one of them. Unanimously they were against a certain
            move, and because he controlled a majority of the stock he had his
            way. And afterwards, they all came and said to him, “My goodness,
            how did you know?”
          </p>
          <p>
            What am I doing? Talking about meditation? I am. I’m talking about
            the breakthrough; how little by little the wall or veil between what
            we call objective life and the psychic world disappears. When the
            awakening comes, it comes in the genital center. The awakening to
            the psychic world comes in the genital center. Back in the
            mid-Victorian puritanical age I used to have to skate around and
            talk cryptically. I don’t anymore. This used to be so secret that an
            allegorical story was told about the awakening of consciousness, the
            Power-to-be-conscious in the psychic side awakening on the
            generative level. It went I like this: Once upon a time, my dear
            little children, there were some shepherds tending their flocks at
            night. And suddenly angel voices sang, “Peace on earth, good will
            among men.” And the shepherds saw a brilliant star, and they
            followed that star and found that it was over a stable, and they
            entered that stable, and there in the manger of the stable there was
            a beautiful newborn babe. And from afar off, three wise men also saw
            the star and made a long journey across the burning sands and came
            to the place where the babe was born, and searched diligently, and
            attracted so much attention in searching that old King Herod heard
            of it and got frightened and sent out all of his legions to kill all
            the newborn within the year to be sure he would get the right one.
          </p>
          <p>
            That was the story to camouflage the awakening of the consciousness
            and the vital force, energy, in the generative level of the psychic
            nature. Need I elucidate the story? Here, in the manger, where we
            have heretofore fed the animals, the power, energy, awakens, and it
            is the beginning of the birth of a newborn one in the Light’s
            Regions. I think it’s the most beautiful story ever told.
          </p>
          <p>
            That force, when it awakens, very often separates men and women.
            Very often one still wants to function just by sensation; the other
            has to have a more subtle wooing response. One shall be taken and
            the other left. All sorts of physiological, mental and psychological
            maladjustments result from not being mated with one who knows how to
            function on that level, responsively, one with another.
          </p>
          <h4 style={{ fontWeight: "bold", paddingTop: "40px" }}>
            Getting Functional with Vitvan
          </h4>
          <p>
            The force does not remain on that level. It rises. Notice the three
            lines running up through the circles on the chart. The outside two
            lines, like the negative and positive lines of force, spiral upward.
            One turns one way end one the other. That is the ancient symbol of
            the caduceus, Hermes’ staff. Our modern medical association has
            adopted that ancient symbol. I wonder if they understand it. One
            cannot function on a relatively higher level without the energy with
            which to function. So little by little those currents are directed
            upward, until “where three ways meet, go into Jerusalem” (go into
            the kingdom), “and in an upper room” (notice the symbolism) “prepare
            the last supper” (the death of the personality, the resurrection of
            consciousness in the Light.) Read that Good Book. It’s all there.
          </p>
          <p>
            The ida-pingala currents go ahead. Another allegory is John the
            Baptist, who “goeth ahead and prepareth the way, but there is One
            coming after me who is greater than I,” etc. Those currents have to
            go ahead and prepare the way for the awakening of a greater power,
            the speirema, the kundalini, the fire, because it would be
            absolutely disastrous for that greater power to awaken if the
            psychic self was not purified. That going ahead and preparing the
            way, we call, in our work, the purificatory period on the path.
            That’s our label for it, because as the force rises it throws out on
            the periphery of the psyche everything that cannot be utilized in
            the new vesture, the fire body. Everything must go out, and it is
            cast out on the periphery before it is cast out entirely.
          </p>
          <p>
            To accomplish this, or to co-operate with it, constitutes the object
            of meditation. Sitting still and trying to hold your thoughts still
            and getting still and mumbling something, like, “My own shall come
            to me,” is the most dangerous thing that one can do, because he is
            opening himself to any influence that might be around in the psychic
            atmosphere. The most dangerous thing to do is to sit negative,
            supine, waiting for something to happen, the Lord to speak to you,
            or what have you. Meditation is the most positive attitude that one
            can assume.
          </p>
          <p>
            Let me backtrack now and pick up the factors and put them together.
            Here is the way we meditate, the way we practice co-operation with
            the Natural Order Process. We sit relaxed, hands resting on our
            thighs, palms up; the spine is held straight to allow the forces to
            flow freely. We focus attention upon one or the other of the psychic
            centers, wherever we happen to be in our ongoing. Some even haven’t
            awakened the power in the level of generation. Some have. Some are
            in the solar center, some in the heart center, some in the throat
            center, and some in the pituitary. It doesn’t make any difference
            which center, there’s no ‘high’ or flow’, ‘good’ or ‘bad’: we’re
            dealing with energy, just like electricity. There’s no ‘good’ or
            ‘bad’ about electricity. You function with this energy on the level
            upon which you want to function.
          </p>
          <p>
            With attention focused upon the psychic centers, begin to breathe
            deeply. Gradually breathe air into your lungs until they are full—a
            full breath. Keeping your focus on one center, breathe air into your
            lungs while you visualize, imagine, realize you are charging the
            battery, you’re taking in energy. Oxygen is another label for fire,
            energy. Just as we convert the food that we ingest into energy
            before it is available to the cellular structure, so we convert the
            oxygen into fire by the breathing process. You are understandingly
            co-operating with the process now. “With all of thy getting, get
            understanding.” Don’t go mooning around; work in the clear light of
            understanding at all times.
          </p>
          <p>
            Focus now, we’ll say, on the heart center, and fill your lungs full
            of air, taking a full breath, and as you do this realize, actually
            experience in feeling, that you are converting oxygen into fire,
            into energy. Hold the focus and exhale slowly and gradually, and as
            you exhale feel that this heart center, for instance, is like a
            powerful searchlight that is shining in all directions, and that
            shining is breathing out love to all creation. Breathe it out to
            everything—the grass, the flowers, the earth and the trees, the dogs
            and the cats— not just to some one. The world needs this power to
            dispel the ugly destructive forces that are being released
            elsewhere. Breathe this power out, breathing out love, to
            everything. Hold your focus as you do this. Practice that five
            minutes to start with. After you do it five minutes four or five
            weeks or so, increase the time until you can do it for fifteen,
            twenty, thirty minutes. You can’t do that at first; but you can
            build yourself up to where you can do it.
          </p>
          <p>
            The focus of attention is very important on the grounds that the
            psychic nature operates under the power of suggestion. It doesn’t
            operate any other way until it is free, liberated, independent and
            well developed. After a few months of holding the focus, your psyche
            finds out what you are trying to do and will do it whether you think
            of it or not. We condition the psyche over the years until it
            operates under the suggestion whether we think of it or not. That is
            the point.
          </p>
          <p>
            Gradually we bring the force up until we focus at the crown. The
            focusing at the crown awakens the power in the sacral center and the
            greater One awakens and comes in and turns you over into the Mind
            Level, into the Supernal Light.
          </p>
          <p>
            So much for meditation. Now I’m going to make a few comments about
            it. Those of us who have been practicing this form of meditation for
            a number of years find this: we’re busy with the housework or the
            job or whatever we are doing, and we are so busy we don’t think
            there is any need to make preparation for something that is
            destructive. Suddenly, right in the midst of being objectively busy,
            we’ll feel the forces beginning to rise and intensify. We have
            learned through the years that the psyche knows something is
            impending that the brain, the objective person, doesn’t know; and so
            the psyche is getting ready to meet it or is lifting the forces
            above the level that it is on. If one can get his forces above the
            level that the impending menace is on he is immune to it. It cannot
            touch him because it can’t get off its level and he’s already on a
            higher level.
          </p>
          <p>
            Train and discipline your psyche until your psyche begins to do this
            automatically. No, it’s not actually automatic; there’s wisdom,
            knowledge and consciousness involved. The psyche knows much more
            than the brain knows about anything and everything. When the psyche
            knows something is going to happen that is destructive or
            disagreeable or that we should be protected from, it goes to work
            and raises the force, and the raising of those forces attracts our
            attention. So we co-operate with it. Very often that happens almost
            daily. Many other things like that eventuate as a result of this
            form of meditation. It opens up whole new worlds teeming with life,
            teeming with people, teeming with activity, and full of the most
            fascinating discoveries while we take the journey to the promised
            land.
          </p>
          <p>
            Meditation is the key to the whole thing, and if you get hold of
            that key you will have something extremely valuable. If you don’t
            want it, leave it alone, stay out of it. The time will come when you
            will want this more than you want anything else.
          </p>
        </Wrapper>
        <BottomSpacer />
      </Layout>
    </>
  )
}
export default Meditation

export const query = graphql`
  query MeditationQuery {
    allSanityImages(
      filter: {
        title: {
          in: ["The Four Somatic Divisions of 'Man'", "Vitvan at Table 2"]
        }
      }
    ) {
      nodes {
        title
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`
